import * as React from 'react'
import { cn } from '@/lib/utils'
import { H3 } from '@/v1/@core/typography'
import TabContent from '@/custom-components/tab-section/TabContent'
import { StyledAccordion, StyledAccordionSummary } from '@/v1/styled-components/tab-section'

export default function AccordionForSmallDevices({ data, defaultExpanded = 0, tabClassNames }) {
  const [expanded, setExpanded] = React.useState(defaultExpanded)

  const handleChange = panel => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {data.length > 0 &&
        data.map((eachPanel, panelIndex) => {
          return (
            <StyledAccordion
              sx={{
                '.MuiAccordionSummary-root.Mui-expanded': { borderBottom: '1px solid #A796F2' }
              }}
              key={panelIndex}
              expanded={expanded === panelIndex}
              onChange={handleChange(panelIndex)}
            >
              <StyledAccordionSummary aria-controls='payment-management' id='payment-management'>
                <H3 className={cn('', { 'text-blue-clear': expanded === panelIndex })}>
                  {eachPanel?.label?.html ? eachPanel?.label?.html : eachPanel?.label}
                </H3>
              </StyledAccordionSummary>

              <TabContent key={panelIndex} data={eachPanel} tabClassNames={tabClassNames} />
            </StyledAccordion>
          )
        })}
    </>
  )
}
