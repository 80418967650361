import React, { HTMLProps } from 'react'
import { cn } from '@/lib/utils'
import { ParsedContent } from '@/types/global'
import { H2, H3 } from '@/v1/@core/typography/Typography'

export interface SectionHeaderProps {
  title: ParsedContent
  titlePart?: ParsedContent
  titleSlug?: ParsedContent
  titlePrefix?: ParsedContent
  isInlineTitle?: boolean
  isReverseTitleColor?: boolean
  content?: ParsedContent
  headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  subtitleTag?: React.ComponentType<React.HTMLProps<HTMLParagraphElement>>
  children?: React.ReactNode
  headingTagClasses?: HTMLProps<HTMLElement>['className']
  className?: HTMLProps<HTMLElement>['className']
}

const SectionHeader = ({
  title,
  titlePart,
  titleSlug,
  isInlineTitle = false,
  isReverseTitleColor = false,
  content,
  headingTag,
  titlePrefix,
  subtitleTag,
  children,
  headingTagClasses,
  className
}: SectionHeaderProps) => {
  // Default to H2 if not provided
  const HeadingTag = headingTag || H2

  // Default to H3 if not provided
  const SubtitleTag = subtitleTag || H3

  return (
    <>
      <div className={cn('pb-6 text-center xl:pb-12', className)}>
        <HeadingTag className={cn('text-center', headingTagClasses)}>
          {!!titleSlug?.html && (
            <span className={cn('block text-xl font-semibold text-white', titleSlug?.classes)}>{titleSlug?.html}</span>
          )}

          {!!titlePrefix?.html && (
            <>
              <span className={cn('text-black-pearl', titlePrefix?.classes)}>{titlePrefix?.html}</span>
            </>
          )}
          <span className={cn('text-blue-bell', { 'text-black-pearl': isReverseTitleColor }, title?.classes)}>
            {title?.html}{' '}
          </span>
          {titlePart?.html ? (
            <>
              {!isInlineTitle && <br />}

              <span className={cn('text-black-pearl', { 'text-blue-bell': isReverseTitleColor }, titlePart?.classes)}>
                {titlePart.html}
              </span>
            </>
          ) : null}
        </HeadingTag>

        {content?.html ? (
          <SubtitleTag variant={'subtitle'} className={cn('mt-[6px] lg:mt-3', content?.classes)}>
            {content?.html}
          </SubtitleTag>
        ) : null}

        {children}
      </div>
    </>
  )
}

export default SectionHeader
