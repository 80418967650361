/**
 * @desc This component is deprecated and will be removed in the future.
 * Please use `genAspectImage` instead.
 */
export const genLocalImage = (src, alt = '', customStyles = {}, bodySx = {}) => {
  return { src, alt, customStyles, bodySx }
}

export const genAspectImage = (src, alt = '', className = '', imgClassName = '', aspectClassName = '') => {
  return { src, alt, className, imgClassName, aspectClassName }
}

export const genContent = (html, sx = {}) => {
  return { html, sx }
}
export const genButton = (html, url, sx = {}, icon = false) => {
  return { html, url, sx, icon }
}
export const genImage = (path, sx = {}) => {
  return { path, sx }
}
