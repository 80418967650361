const styleConfig = {
  colors: {
    dark: {
      indigo: '#181059'
    },
    black: '#000000',
    white: '#FFFFFF',
    offWhite: '#D8D8D8',
    azureishWhite: '#D8E2F3',
    red: '#FF0000',
    lightGray: '#D3D3D3',
    gray: '#7F7F7F',
    green: '#00B050',
    lightGreen: '#70AD47',
    pastelGreen: '#94E569',
    chineseWhite: '#E1EFD8',
    papayaWhip: '#FFF2D0',
    ripeMango: '#FFBA20',
    nyanza: '#E8FADF',
    purple: '#8A42C6',
    purpleCmyk: '#631DC1',
    orange: '#ED7D31',
    yellow: '#FFFF00',
    periwinkle: '#CCCCFF',
    blue: '#4472C4',
    cultured: '#F5F7F8',
    primary: '#8B8DFF',
    lightPrimary: '#E7E7FF',
    classicRose: '#FECEE6',
    charcoal: '#32475CDE'
  },
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '16px'
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800
  },
  typographyStyle: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#7F7F7F'
  }
}

export default styleConfig
