import { FC, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import Accordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'

import Icon from '@/@core/components/icon'

export const StyledAccordion: FC<AccordionProps> = styled(
  forwardRef<HTMLDivElement, AccordionProps>((props, ref) => (
    <Accordion disableGutters elevation={0} square ref={ref} {...props}>
      {props.children}
    </Accordion>
  ))
)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

export const StyledAccordionSummary: FC<AccordionSummaryProps> = styled(
  forwardRef<HTMLDivElement, AccordionSummaryProps>((props, ref) => (
    <AccordionSummary
      expandIcon={<Icon icon={'iconamoon:arrow-down-2'} width={26} height={26} />}
      ref={ref}
      {...props}
    />
  ))
)(({ theme }) => ({
  borderRadius: '6.86px',
  background: '#FFF',
  boxShadow: '0px 1.9px 6.1px rgba(8, 15, 52, 0.06)',

  '&.Mui-expanded': { borderBottom: '1px solid #A796F2' },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#1678FB',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    color: '#000E21',
    fontSize: '18px',
    lineHeight: '1.625rem',
    letterSpacing: '0.0015rem',
    fontWeight: 400
  }
}))
